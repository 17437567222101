<template>
  <div>
    <b-modal id="modal-medicine" size="xl" title="Pilih Obat" hide-footer>
      <div class="row">
        <div class="col-md-12">
          <b-button class="ml-2" variant="success" @click="saveItemChoosen">
            <i class="fas fa-plus"></i> Tambahkan Item Terpilih
          </b-button>
        </div>
        <div class="col-md-12">
          <Table
            purpose="modal"
            modalPurpose="modal"
            :isHospitalTransaction="isHospitalTransaction"
            :selectedItem="items"
            :checkbox="checkbox"
            :isSortcut="isSortcut"
            :isAbjad="isAbjad"
            @chooseMedicine="chosenMedicine"
            @packageMedicine="packageMedicine"
            :secondPurpose="secondPurpose"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/medicine/TablePromo.vue";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    isAbjad: Boolean,
    secondPurpose: {
      type: String,
      default: "listing",
    },
  },

  data() {
    return {
      items: [],
    };
  },

  components: {
    Table,
  },

  methods: {
    chosenMedicine(value, e) {
      if (e) {
        this.items.push(value);
      } else {
        let found = this.items.findIndex((x) => x.id == value.id);
        let index = this.items.indexOf(this.items[found]);
        this.items.splice(index, 1);
      }

    },

    async saveItemChoosen() {
      // save data
      let data = {
        items: this.items,
      };

      let response = await module.submit(
        data,
        "/promos/add-item-medicines/" + this.$route.params.id
      );
      // Check Response
      if (response.state == "success") {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$bvModal.hide("modal-medicine");
        this.$emit("updateServiceItemMedicine");
      }
    },

    packageMedicine(datas) {
      this.$emit("packageMedicine", datas);
    },
  },
  mounted() {
  },
};
</script>

<style>
</style>